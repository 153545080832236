@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.background-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    $grey-200,
    $grey-200 5px,
    $white 5px,
    $white 10px
  );
}

.v-stepper__header {
  box-shadow: none;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.v-stepper__step {
  padding: 1rem 1.5rem;
}

.v-tooltip__content {
  font-size: 12px;
}

.v-tooltip__content.menuable__content__active {
  background: rgba(map-get($grey, 'darken-2'), 1);
  opacity: 1 !important;
}

@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.timer-wrapper {
  text-align: center;
  line-height: 1.2rem;
}
.timer {
  color: white;
  .brand-local & {
    color: #414042 !important;
  }
}

@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.close-btn {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 16px;
  height: auto;
}
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10vh;

  @include media('sm-and-up') {
    margin-top: 20px;
  }
}
.logo__img {
  .brand-iga & {
    max-width: 164px;
  }
  .brand-local & {
    max-width: 186px;
  }
  .brand-supa & {
    max-width: 168px;
  }
}
.logo__toggle-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: #f9f9f9;

  @include media('xs-only') {
    right: 50%;
    transform: translateY(-50%) translateX(140px);
  }

  ::v-deep .v-btn__content {
    width: 24px;
    flex-wrap: wrap;
  }
  ::v-deep .v-icon {
    color: $brand-red;
    height: 8px;
  }
}
.btn-user {
  text-transform: initial;

  &:hover {
    background-color: transparent;
  }
}
.btn-user__icon {
  font-size: 30px !important;
}
.store-card__single-store {
  font-weight: bold;
}
.store-title ::v-deep .v-input {
  font-size: 1.125rem;
  font-weight: 700;
  height: 40px;

  .v-icon {
    font-size: 30px;
  }
}

// pushes user select below nav @xs
.main-nav__lower {
  display: flex;
  flex-direction: column;
}
.main-nav__links {
  @include media('xs-only') {
    order: -1;
  }
}
//

.main-nav__link {
  align-items: center;
  border-radius: 6px;
  display: flex;
  font-family: $font-family-oswald;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: $global-transition;
  height: 60px;
  font-size: 1.1rem;
  margin-top: 14px;

  @include media('sm-and-up') {
    height: 70px;
    font-size: 1.3rem;
  }
}

// hide select controls (disable this if debugging!)
.v-select.is-disabled ::v-deep {
  div[role='button'] {
    padding-right: 0 !important;
  }
  .v-select__selection {
    max-width: 100%;
    margin-right: 0;
  }

  input,
  .v-input__append-inner {
    display: none;
  }
}

/*
 * theming
 */
.store-card {
  .brand-iga & {
    background-color: #363636;
  }
  .brand-local & {
    background-color: #efe9de;
  }
  .brand-supa & {
    background-color: #ffd100;
  }
}

.store-card__select ::v-deep .v-input__slot {
  .brand-iga & {
    background-color: #363636 !important;
  }
  .brand-local & {
    background-color: #efe9de !important;
  }
  .brand-supa & {
    background-color: #ffd100 !important;
  }
}

.store-card__select ::v-deep .v-select__selection,
.store-card__select ::v-deep .v-icon,
.store-card__single-store,
.btn-user__icon,
.btn-user__name {
  color: white;
  .brand-local & {
    color: #414042 !important;
  }
}
.main-nav {
  .brand-local & {
    background-color: #efe9de;
  }
  .brand-supa & {
    background-color: #ffd100;
  }
}
.close-btn {
  .brand-local & {
    color: #414042;
  }
}

.main-nav__link {
  .brand-iga & {
    color: white;

    &:hover {
      background: transparentize(#fce9e8, 0.75);
      color: #fce9e8;
    }
    &.active {
      background: #fce9e8;
      color: #e4291b;
    }
  }
  .brand-local & {
    color: #414042;

    &:hover {
      background: transparentize(white, 0.65);
      color: rgba(#d6462e, 0.8);
    }
    &.active {
      background: white;
      color: #d6462e;
    }
  }
  .brand-supa & {
  }
}

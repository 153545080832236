@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.content-container {
  max-width: none;
}

::v-deep {
  .category,
  .v-data-footer {
    .v-icon {
      font-size: 14px;
    }
  }

  .v-input__slot {
    font-size: 14px;
  }

  .v-label {
    font-size: 13px;
    color: $black;
  }
  .v-icon--link {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .v-file-input {
    label {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    button {
      &.v-icon--link {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
}

.stage-wrapper {
  height: calc(100vh - 100px);

  > div {
    height: 100%;
  }
}

.v-card__title {
  word-break: break-word;
}

.v-stepper {
  box-shadow: none;
}

.drop-zone {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &.is-dragging {
    background-color: rgba(#a5d6a7, 0.4);
  }

  &.is-image-imported {
    z-index: -1;
  }

  &__arrow {
    color: black;
    padding: 8px;
    border-radius: 6px;
    // via https://kovart.github.io/dashed-border-generator/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='4' stroke-dasharray='6%2c 12' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 6px;
  }

  &__desc {
    font-size: 18px;
    color: black;
    margin-top: 22px;
    margin-bottom: 12px;
    width: 100%;
  }

  &__formats {
    font-size: 12px;
    color: $grey-600;
    margin-top: 10px;
    margin-bottom: 26px;
    width: 100%;
  }

  &__select-existing {
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
    color: black;
  }

  &__select-existing-btn {
    text-transform: none;
    color: black;
    border: solid 1px $grey-200 !important;
  }
}

.drop-zone__content {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  text-align: center;

  .is-image-imported & {
    display: none;
  }

  .is-dragging & {
    pointer-events: none;
  }
}

::v-deep .reset-img-btn {
  display: none;
  margin-top: 10px;
  text-decoration: underline;
  color: black;

  .drop-zone.is-image-imported + .stage-wrapper & {
    display: inline-block;
  }
}
